import React from 'react'
import { graphql, He } from 'gatsby'

import SEO from '../components/SEO'
import Layout from '../components/Layout'
import AuthorDetailContent from '../components/ContentBlocks/AuthorDetailContent'

const AuthorDetailPage = ({ data }) => {
  const page = data.prismicNpfAuthor
  if (!page) {
    return null
  }

  const { author_image, bio, member_type, name, title, seo_embed_code } =
    page.data

  return (
    <>
      <SEO
        seo={{
          seo_embed_code: seo_embed_code?.text ?? null,
        }}
      />
      <Layout>
        <AuthorDetailContent
          bio={bio}
          image={author_image}
          memberType={member_type}
          name={name}
          title={title}
        />
      </Layout>
    </>
  )
}

export const query = graphql`
  query AdvanceArticleQuery($id: String) {
    prismicNpfAuthor(id: { eq: $id }) {
      data {
        author_image {
          alt
          thumbnails {
            executive_team_desktop {
              url
            }
          }
        }
        bio {
          richText
        }
        member_type
        name {
          text
        }
        title {
          text
        }
        seo_embed_code {
          text
        }
      }
    }
  }
`

export default AuthorDetailPage
